import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

// import HeaderIcon from "../images/coil-icon.inline.svg"
import HeaderIcon from "../images/logo-simple.inline.svg"

import "normalize.css"
import "../sass/styles.scss"

const siteTitle = "Back Stick Electric"
const year = new Date().getFullYear()

export default function Layout({ pageTitle, children }) {
  return (
    <div>
      <Helmet>
        <title>
          {pageTitle} | {siteTitle}
        </title>
      </Helmet>

      <header id="site-header">
        <nav className="container">
          <Link to="/" id="site-logo">
            <HeaderIcon />
          </Link>
          <ul id="site-menu">
            <li>
              <Link to="/">About</Link>
            </li>
            {/* <li>
              <Link to="/blog/">Blog</Link>
            </li> */}
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
          </ul>
        </nav>
      </header>
      <main id="site-main">{children}</main>
      <footer id="site-footer">
        <section className="container">
          <p>
            &copy; {year} Back Stick Electric
            <br />
            Solar and Electrical Services
            <br />
            60 Fergus St, Kingston, ON K7K 4L2
            <br />
            Thor: (613) 453-8084  &mdash; Ian: (613) 329-9120
            <br />
            ECRA/ESA Lic 7015119
          </p>
        </section>
      </footer>
    </div>
  )
}
